import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { ThemeProvider } from "styled-components";
import { UserProvider } from "providers/user";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./theme";
import "./index.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import client from "graph-ql";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import "moment/locale/es";

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router basename="/">
          <ConfigProvider locale={esES}>
            <App />
          </ConfigProvider>
        </Router>
      </UserProvider>
    </ThemeProvider>
  </ApolloProvider>,

  document.getElementById("root")
);
