import React, { useEffect } from "react";
import { Spin } from "antd";
import theme from "theme";

const Public = () => {
  useEffect(() => {
    // Check if the custom scheme can be opened
    window.location.href = "paradise-shop://success";
    // Optionally, redirect to an error or fallback page after a timeout
    setTimeout(() => {
      window.location.href = "https://dev-app.paradiseapps.mx"; // Fallback URL
    }, 5000); // 5 seconds timeout for the app to respond
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {" "}
      <Spin size="large" style={{ color: theme.colors.primary }} />
    </div>
  );
};

export default Public;
