import React from "react";
import { withRouter } from "react-router-dom";
import MainLayout from "layouts/main";
import AuthLayout from "layouts/auth";
import Authentication from "views/private/routes/authentication";
import CRM from "views/private/routes/crm";
import { useUser } from "providers/user";

const Private = () => {
  const { user } = useUser();

  if (!user) {
    return (
      <AuthLayout>
        <Authentication />
      </AuthLayout>
    );
  } else {
    return (
      <MainLayout>
        <CRM />
      </MainLayout>
    );
  }
};

export default withRouter(Private);
