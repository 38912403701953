import media from "./media";

const theme = {
  media,
  colors: {
    primary: "#71B530",
    secondary: "#13192D",
  },
};

export default theme;
