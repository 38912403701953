import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Typography, Button } from "antd";
import {
  UserAddOutlined,
  FolderOpenTwoTone,
  FundTwoTone,
  LogoutOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { useUser } from "providers/user/index";

import { Menu } from "./elements";
import logo from "./logo.png";

const { Item, SubMenu } = Menu;
const { Text } = Typography;
const Sidebar = ({ history }) => {
  const { logout } = useUser();
  let routes = (
    <>
      <Item icon={<FundTwoTone />} key="/dashboard">
        <Link to="/dashboard">
          <Text style={{ color: "white" }} strong>
            Dashboard
          </Text>
        </Link>
      </Item>
      <Item icon={<FundTwoTone />} key="/sync-operations">
        <Link to="/sync-operations">
          <Text style={{ color: "white" }} strong>
            Operaciones
          </Text>
        </Link>
      </Item>
      <Item icon={<FundTwoTone />} key="/orders">
        <Link to="/orders">
          <Text style={{ color: "white" }} strong>
            Órdenes Completadas
          </Text>
        </Link>
      </Item>
      <Item icon={<FundTwoTone />} key="/abandoned">
        <Link to="/abandoned">
          <Text style={{ color: "white" }} strong>
            Carritos Abandonados
          </Text>
        </Link>
      </Item>
      <Item icon={<FundTwoTone />} key="/franchise">
        <Link to="/franchise">
          <Text style={{ color: "white" }} strong>
            Franquicias
          </Text>
        </Link>
      </Item>
      <SubMenu
        icon={<FolderOpenTwoTone />}
        style={{ background: "none" }}
        title={
          <Text style={{ color: "white" }} strong>
            Productos
          </Text>
        }
      >
        <Item icon={<FundTwoTone />} key="/products">
          <Link to="/products">
            <Text style={{ color: "white" }} strong>
              Productos
            </Text>
          </Link>
        </Item>
        <Item icon={<FundTwoTone />} key="/landing">
          <Link to="/landing">
            <Text style={{ color: "white" }} strong>
              Landing
            </Text>
          </Link>
        </Item>
        <Item icon={<FundTwoTone />} key="/collaborations">
          <Link to="/collaborations">Artistas</Link>
        </Item>
        <Item icon={<FundTwoTone />} key="/categories/wellness">
          <Link to="/categories/wellness">Bienestar</Link>
        </Item>
        <Item icon={<FundTwoTone />} key="/categories/smoke">
          <Link to="/categories/smoke">Smoke</Link>
        </Item>
      </SubMenu>
      <SubMenu
        icon={<FolderOpenTwoTone />}
        style={{ background: "none" }}
        title={
          <Text style={{ color: "white" }} strong>
            Promociones
          </Text>
        }
      >
        <Item icon={<UserAddOutlined />} key="/banners">
          <Link to="/banners">Banners</Link>
        </Item>
        <Item icon={<UserAddOutlined />} key="/bundles">
          <Link to="/bundles">Bundles</Link>
        </Item>
        <Item icon={<UserAddOutlined />} key="/discounts">
          <Link to="/discounts">Discounts</Link>
        </Item>
        <Item icon={<BellOutlined />} key="/notifications">
          <Link to="/notifications">Notificaciones</Link>
        </Item>
      </SubMenu>
      <SubMenu
        icon={<FolderOpenTwoTone />}
        title={
          <Text style={{ color: "white" }} strong>
            Usuarios
          </Text>
        }
      >
        <Item icon={<UserAddOutlined />} key="/clients">
          <Link to="/clients">Clientes</Link>
        </Item>
        <Item icon={<UserAddOutlined />} key="/admins">
          <Link to="/admins">Admins</Link>
        </Item>
        <Item icon={<UserAddOutlined />} key="/employee">
          <Link to="/employee">Empleados</Link>
        </Item>
      </SubMenu>
    </>
  );
  return (
    <div style={{ height: "100vh", background: "none" }}>
      <Image
        style={{
          margin: "10px auto",
          marginTop: 20,
          display: "block",
          paddingLeft: 40,
          paddingRight: 40,
        }}
        height={35}
        src={logo}
        alt=""
      />
      <Menu
        theme="dark"
        style={{ marginTop: 30, background: "none" }}
        defaultSelectedKeys={history.location.pathname.toLowerCase()}
        selectedKeys={history.location.pathname.toLowerCase()}
        mode="inline"
      >
        {routes}
      </Menu>
      <Button
        type="primary"
        style={{
          position: "absolute",
          bottom: "0%",
          left: "0%",
          width: "10%",
          margin: "20px",
        }}
        onClick={() => {
          logout();
        }}
      >
        <LogoutOutlined />
        Salir
      </Button>
    </div>
  );
};

export default withRouter(Sidebar);
