import styled, { keyframes } from "styled-components";

const HeaderKeyFrame = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const AuthWrapper = styled.div``;

const DisplayWrapper = styled.div`
  width: 100%;
  background: linear-gradient(to right, rgba(36, 87, 27, 0.35) 0%, rgba(36, 87, 27, 0.15) 50%, rgba(36, 87, 27, 0.05) 100%);
  animation: ${HeaderKeyFrame} 20s ease infinite;
  background-size: 200% 100%;
  }
`;

// 24571B
export { AuthWrapper, DisplayWrapper };
