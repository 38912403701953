import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import { AuthWrapper, DisplayWrapper } from "./elements";

const AuthLayout = ({ children }) => {
  return (
    <Row style={{ overflow: "hidden" }}>
      <DisplayWrapper>
        <div
          style={{
            height: "100vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: 0,
            margin: 0,
          }}
        >
          <AuthWrapper>{children}</AuthWrapper>
        </div>
      </DisplayWrapper>
    </Row>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AuthLayout;
