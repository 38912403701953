import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import Loadable from "react-loadable";

const Dashboard = Loadable({
  loader: () => import("./routes/dashboard"),
  loading: TopBarProgress,
});

const Orders = Loadable({
  loader: () => import("./routes/orders"),
  loading: TopBarProgress,
});

const AbandonedOrders = Loadable({
  loader: () => import("./routes/abandoned-orders"),
  loading: TopBarProgress,
});

const SyncOperation = Loadable({
  loader: () => import("./routes/sync-operations"),
  loading: TopBarProgress,
});

const Products = Loadable({
  loader: () => import("./routes/products"),
  loading: TopBarProgress,
});

const Banners = Loadable({
  loader: () => import("./routes/banners"),
  loading: TopBarProgress,
});

const Bundles = Loadable({
  loader: () => import("./routes/bundles"),
  loading: TopBarProgress,
});

const Franchise = Loadable({
  loader: () => import("./routes/franchise"),
  loading: TopBarProgress,
});

const Discounts = Loadable({
  loader: () => import("./routes/discounts"),
  loading: TopBarProgress,
});

const Collaborations = Loadable({
  loader: () => import("./routes/collaborations"),
  loading: TopBarProgress,
});

const Clients = Loadable({
  loader: () => import("./routes/clients"),
  loading: TopBarProgress,
});

const Admins = Loadable({
  loader: () => import("./routes/admins"),
  loading: TopBarProgress,
});

const Employee = Loadable({
  loader: () => import("./routes/employee"),
  loading: TopBarProgress,
});

const Notifications = Loadable({
  loader: () => import("./routes/notifications"),
  loading: TopBarProgress,
});

const ProductCategories = Loadable({
  loader: () => import("./routes/product-categories"),
  loading: TopBarProgress,
});

const Landing = Loadable({
  loader: () => import("./routes/landing"),
  loading: TopBarProgress,
});

const Dash = () => (
  <Switch>
    <Route path="/dashboard">
      <Dashboard />
    </Route>
    <Route path="/orders">
      <Orders />
    </Route>
    <Route path="/abandoned">
      <AbandonedOrders />
    </Route>
    <Route path="/products">
      <Products />
    </Route>
    <Route path="/franchise">
      <Franchise />
    </Route>
    <Route path="/banners">
      <Banners />
    </Route>
    <Route path="/bundles">
      <Bundles />
    </Route>
    <Route path="/discounts">
      <Discounts />
    </Route>
    <Route path="/collaborations">
      <Collaborations />
    </Route>
    <Route path="/clients">
      <Clients />
    </Route>
    <Route path="/sync-operations">
      <SyncOperation />
    </Route>
    <Route path="/admins">
      <Admins type="admin" />
    </Route>
    <Route path="/employee">
      <Employee type="employee" />
    </Route>
    <Route path="/notifications">
      <Notifications type="notification" />
    </Route>
    <Route path="/categories/:categoryType">
      <ProductCategories type="categories" />
    </Route>
    <Route path="/landing">
      <Landing type="landing" />
    </Route>
    <Redirect to="/dashboard" />
  </Switch>
);

export default Dash;
